<template>
  <div class="about-company__page">
    <bread-crumb></bread-crumb>
    <section class="about-company container container__mini2">
      <div class="about-company__descr" v-html="data.description"></div>
      <h2 class="about-company__title">{{ data.title }}</h2>
    </section>

    <SliderAboutCompany :data="users" />

    <section class="about-company container container__mini2 mt80">
      <div class="about-company__descr" v-html="data.description_down"></div>
      <div class="about-company__results results-company" v-if="json">
        <div class="results-company__item" v-for="item in json" :key="item">
          <div class="results-company__left">
            <img
              :src="url + item.image"
              rel="preload"
              width="75"
              height="60"
              alt="results-company"
            />
          </div>
          <div class="results-company__right">
            <pre>{{ getDescription(item, $i18n.locale) }}</pre>
          </div>
        </div>
      </div>
      <h2 class="about-company__title">{{ data.title_down }}</h2>
    </section>
    <BatchSolutions :data="slider" />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const SliderAboutCompany = defineAsyncComponent(() =>
  import("@/components/SliderAboutCompany.vue")
);
const BatchSolutions = defineAsyncComponent(() =>
  import("@/components/BatchSolutions.vue")
);
const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
import axios from "axios";

export default {
  components: {
    BreadCrumb,
    BatchSolutions,
    SliderAboutCompany,
  },
  props: {
    url: {
      type: String,
      employee: Object,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      data: [],
      slider: [],
      users: [],
      json: [],
    };
  },
  methods: {
    getDescription(data, locale) {
      if (locale === "uk") {
        return data.description_uk;
      } else {
        return data.description_ru;
      }
    },
    async getAboutComPage() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/about-company-page",
          config
        );
        this.data = response.data.data;
        this.json = JSON.parse(response.data.data.data);
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getSliders() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/all-sliders",
          config
        );
        this.slider = response.data.home_slider.data.services.data;
        this.users = response.data.about_company_slider.data.users.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },

  created() {
    this.getSliders();
    this.getAboutComPage();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.about-company {
  &__descr {
    max-width: 800px;
    margin: 0 auto 1em;
    line-height: calc(25 / 16);

    pre {
      @media (max-width: 575.98px) {
        white-space: initial;
      }
    }
  }

  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 50, 25);
    @include property("margin-bottom", 50, 25);

    .mt80 & {
      @include property("margin-bottom", 100, 45);
    }
  }
}

.results-company {
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  @include property("margin-top", 40, 20);
  @include property("margin-bottom", 65, 30);
  &__item {
    display: grid;
    grid-template-columns: 75px 1fr;
    gap: 11px;
    align-items: center;

    &:not(:last-child) {
      @include property("margin-bottom", 35, 15, true, 1024, 320);
    }
  }

  &__left {
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #f9b80e;
    flex: 0 0 auto;

    img {
      display: block;
      width: 60px;
      height: 60px;
      margin: auto;
      object-fit: contain;
    }
  }

  &__right {
    @include property("font-size", 20, 16, true, 576, 320);
    line-height: calc(25 / 20);
  }
}
</style>
